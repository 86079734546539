.MainContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;
  font-family: 'open sans';
  background-color: #ffffff;
}

.Main {
  font-family: 'Open Sans', sans-serif;
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
}

.MainImage {
  max-width: 30%;
}

@media screen and (max-width: 768px) {
  .Main {
    font-size: 3rem;
  }

  .MainImage {
    max-width: 25%;
  }
}

@media screen and (max-width: 480px) {
  .Main {
    font-size: 2rem;
  }

  .MainImage {
    max-width: 20%;
  }
}
