.mainContainer {
  overflow: hidden;
  height: 400px;
}

.container img {
  max-width: 100%;
  height: auto;
  max-height: 220px;
}

.slick-slide.slick-active:hover {
  border: none;
}
.slick-slide:hover {
  outline: none !important;
  border: none !important;
}
