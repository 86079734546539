.Areas {
  padding: 400px auto;
  margin-top: 150px;
}
.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  width: calc(100% - 40px);
  margin: 0 20px;
  max-width: 1200px;
  padding: 30px;
  box-sizing: border-box;
}

.area-section {
  margin-top: 40px;
}

.areas-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  margin: 0 auto;
  padding: 0 60px;
  width: 100%;
}

.area-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 0px solid gray;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  transition: border 0.5s ease;
  max-width: 100%;
  height: 100%;
  padding: 20px 20px;
  margin: 0 10px;
}

.area-info img {
  width: 100%;
  max-height: 150px;
  object-fit: contain;
  margin-bottom: 10px;
}

.area-info p {
  margin: 0;
  font-size: 1em;
  line-height: 1.4;
}

.area-info:hover {
  border: 3px solid #1e90ff;
}

@media only screen and (max-width: 768px) {
  .areas-container {
    grid-template-columns: repeat(2, 1fr);
    width: calc(100% - 20px);
  }

  .area-info {
    width: 100%;
    max-width: 100%;
    height: auto;
    padding: 10px;
  }

  .area-info img {
    max-width: 100%;
    max-height: 200px;
  }

  .area-info p {
    font-size: 0.9em;
  }
}

@media only screen and (max-width: 480px) {
  .areas-container {
    grid-template-columns: 1fr;
    width: calc(100% - 20px);
  }

  .area-info {
    max-width: 100%;
    height: auto;
    padding: 10px;
  }

  .area-info img {
    max-width: 100%;
    max-height: 150px;
  }

  .area-info p {
    font-size: 0.8em;
  }
}
