.footer {
  background-color: #002141;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 4rem;
  position: relative;
  margin-top: 150px;
}

.footer p {
  margin: 0;
}

.footer ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer ul li {
  margin: 0.5rem 0;
}

.footer a {
  color: white;
  text-decoration: none;
}

.footer-left,
.footer-center,
.footer-right {
  flex: 1;
  margin-right: 10rem;
  margin-left: 5rem;
  margin-top: 3.375rem;
  margin-bottom: 5rem;
}

.footer-left p {
  font-size: 1.5rem;
}

.footer-center {
  margin-left: 20rem;
}

.footer-center p {
  font-size: 3rem;
}

.footer-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.footer-right i {
  margin-right: 0.5rem;
}

.footer-right p + p {
  margin-top: 10px;
}

.footer-right p {
  font-size: 1.75rem;
  margin: 4.75px 0;
  display: flex;
  align-items: center;
  align-self: flex-start;
}

.footer::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: #cf0000;
}
