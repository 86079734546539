.Contact {
  padding: 400px auto;
  margin-top: 150px;
}
span {
  display: block;
  text-align: center;
}
.contact-container {
  display: flex;
  justify-content: center;
  margin: 30px auto;
  max-width: 1200px;
  font-family: 'Open Sans', sans-serif;
}

.contact-box {
  flex-basis: calc(33.33% - 20px);
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 0 10px;
  padding: 20px;
  box-shadow: rgba(206, 216, 230, 0.3) 0px 4px 8px 0px;
  transition: border-color 0.299s ease;
}

.contact-box h3 {
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
}

.contact-box ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-box li:first-child {
  margin-bottom: 10px;
}
.contact-box li li {
  color: #888;
}

.contact-box li:last-child {
  margin-top: 10px;
}

.contact-box img {
  width: 60px;
  height: 60px;
  margin-right: 10px;
  filter: invert(26%) sepia(97%) saturate(4961%) hue-rotate(195deg)
    brightness(102%) contrast(102%);
}

.contact-box:hover {
  border-color: #002141;
}

@media only screen and (max-width: 768px) {
  .contact-container {
    flex-direction: column;
  }
  .contact-box {
    flex-basis: 100%;
    margin: 0 0 20px 0;
  }
}
.iframe {
  display: block; /* iframe etiketinin blok öğesi olarak görüntülenmesini sağlar */
  margin: 0 auto; /* yatayda ortalamak için margin özelliğini kullanır */
}
