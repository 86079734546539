*,
*::after,
*::before {
  margin: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
}
body {
  font-size: 1.6rem;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
  color: rgb(204, 204, 204);
}

.nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 10vh;
  background-color: #002141;
  color: #ffffff;
}

.nav__brand {
  text-transform: uppercase;
}

.nav__brand img {
  width: 120px;
  height: auto;
}

.nav__menu {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 3rem;
}
.nav__toggler {
  display: none;
}
.nav__toggler div {
  width: 2.5rem;
  height: 0.2rem;
  margin: 0.4rem;
  background: rgb(204, 204, 204);
  transition: 0.3s ease-in;
}

@media screen and (max-width: 768px) {
  .nav__toggler {
    display: block;
    cursor: pointer;
  }
  .nav__menu {
    position: fixed;
    top: 7vh;
    right: 0;
    height: 93vh;
    width: 50vw;
    background: rgb(0, 33, 65);
    flex-direction: column;
    transform: translateX(100%);
    transition: 0.5s ease-in;
  }
}
/* Active Class */
.nav__active {
  transform: translateX(0%);
}

/* Toggle Icon Animation */

.toggle .line1 {
  transform: rotate(-45deg) translate(-4px, 5px);
}
.toggle .line2 {
  opacity: 0;
}
.toggle .line3 {
  transform: rotate(45deg) translate(-4px, -5px);
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown__text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  cursor: pointer;
  color: #333;
  background-color: #fff;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}



.show {
  display: block;
}
a {
  text-decoration: none;
  color: rgb(204, 204, 204);
  position: relative;
}

a::before {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #cf0000;
  transition: width 0.4s ease-in-out;
}

a:hover::before {
  width: 100%;
}
